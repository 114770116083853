import React, { useEffect, useRef, useState } from "react";
import "../css/layout.css";
import css from "../Modulecss/Home.module.css";
import axios from "axios";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Rightcontainer from "../Components/Rightcontainer";
import { useDispatch } from "react-redux";
import BetCard from "./BetCard";
import RunningCard from "./RunningCard";
import Header from "../Components/Header";
import io from "../Components/socket";
import socket from "../Components/socket";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import guidebutton from "../../assets/images/guidebutton.png";
import acceptSound from "./accept.mp3";
import findGif from "../css/loading_old.gif";
import playSound from "./accept.mp3";
import { MdOutlineDelete } from "react-icons/md";
import { FiRefreshCcw } from "react-icons/fi";

export default function Homepage({ rungame, homePlay, users }) {
  // BASE URL

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  // +++++++++++++++++++++++++++++
  const history = useHistory();
  let userID = useRef();
  const isMounted = useRef(true);
  let str;

  const [user, setUser] = useState();
  const [create, setCreated] = useState([]);
  const [challangeReqRun, setChallangeReqRun] = useState(false);

  const [game_amount, setGame_amt] = useState();

  const [gameData, setGameData] = useState({});

  const [submitProcess, setProcess] = useState(true);
  const [isLoading, setIsloading] = useState(false);
  const addsetting = localStorage.getItem("sitSetting");
  const addCaseSetting = JSON.parse(addsetting);
  const userId = localStorage.getItem("userId");
  const access_token = localStorage.getItem("token");
  const [disable, setDisable] = React.useState(challangeReqRun);
  const [disable_play, setDisable_play] = React.useState(false);
  const [GameList, setGameList] = useState(homePlay);

  const req_chall_fun = (allgameId) => {
    getPost(allgameId);
  };

  const disablebtn = (id) => {
    setDisable(true);
    setTimeout(() => {
      setDisable(false);
    }, 10000);
    //  sendMessage()
  };

  const disable_play_btn = () => {
    setDisable_play(true);
    setTimeout(() => {
      setDisable_play(false);
    }, 3000);
    // status_fun()
  };
  /// user details end

  const [game_type, setGame_type] = useState(
    useLocation().pathname.split("/")[2]
  );
  const [game_type2, setGame_type2] = useState(
    useLocation().pathname.split("/")[3]
  );
  const [Game_Ammount, setGame_Ammount] = useState("");

  const udata = localStorage.getItem("udata")
  // console.log(udata.Wallet_balance)
  const userData = JSON.parse(udata)
  // console.log(userData.Wallet_balance)

  const ChallengeCreate = (e) => {
    setIsloading(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    if (game_type == "ludoClassicManual") {
      if (Game_Ammount >= 50 && Game_Ammount <= 50000) {
        axios
          .post(
            baseUrl + `challange/create`,
            {
              Game_Ammount,
              Game_type: game_type,
            },
            { headers }
          )
          .then((res) => {
            setGame_amt(res?.data?.data?.Game_Ammount);

            if (res.data.status == false) {
              setIsloading(false);
              Swal.fire({
                title: res.data.msg,
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else if (res.data.msg === "you have already enrolled") {
              setIsloading(false);
              Swal.fire({
                title: "You have already enrolled",
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else if (res.data.msg === "You can set maximum 2 battle.") {
              setIsloading(false);
              Swal.fire({
                title: "You can set maximum 2 battle.",
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else if (res.data.msg === "Insufficient balance") {
              setIsloading(false);
              Swal.fire({
                title: "Insufficient balance",
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else if (
              res.data.msg ===
              "Game amount should be Greater then 50 and less then 50000"
            ) {
              setIsloading(false);
              Swal.fire({
                title:
                  "Game amount should be Greater then 50 and less then 50000",
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else if (res.data.msg === "Set Battle in denomination of 50") {
              setIsloading(false);
              Swal.fire({
                title: "Set Battle in denomination of 50",
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else if (res.data.msg === "Technical Issue, Try after an hour!") {
              setIsloading(false);
              Swal.fire({
                title: "Technical Issue, Try after an hour!",
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else {
              setIsloading(false);
            }
          })
          .catch((e) => {
            setIsloading(false);
            if (e?.response?.status == 401) {
              localStorage.removeItem("token");
              localStorage.removeItem("token");
              window.location.reload();
              setTimeout(() => {
                //  history.push("/login")
              }, 500);
            }
            if (e?.response?.status == 400 || e?.response?.status == 429) {
              Swal.fire({
                title: "Please refresh!",
                icon: "warning",
                confirmButtonText: "OK",
              });
            }
            console.log(e);
          });
      } else {
        setIsloading(false);
        Swal.fire({
          title: "Please Amount should be greater 50 and less 50000!",
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    } else if (game_type == "ludoClassicRich") {
      if (Game_Ammount >= 550 && Game_Ammount <= 100000) {
        axios
          .post(
            baseUrl + `challange/create`,
            {
              Game_Ammount,
              Game_type: game_type,
            },
            { headers }
          )
          .then((res) => {
            alert("600");
            setGame_amt(res?.data?.data?.Game_Ammount);

            if (res.data.status == false) {
              setIsloading(false);
              Swal.fire({
                title: res.data.msg,
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else if (res.data.msg === "you have already enrolled") {
              setIsloading(false);
              Swal.fire({
                title: "You have already enrolled",
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else if (res.data.msg === "You can set maximum 2 battle.") {
              setIsloading(false);
              Swal.fire({
                title: "You can set maximum 2 battle.",
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else if (res.data.msg === "Insufficient balance") {
              setIsloading(false);
              Swal.fire({
                title: "Insufficient balance",
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else if (
              res.data.msg ===
              "Game amount should be Greater then 50 and less then 50000"
            ) {
              setIsloading(false);
              Swal.fire({
                title:
                  "Game amount should be Greater then 50 and less then 50000",
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else if (res.data.msg === "Set Battle in denomination of 50") {
              setIsloading(false);
              Swal.fire({
                title: "Set Battle in denomination of 50",
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else if (res.data.msg === "Technical Issue, Try after an hour!") {
              setIsloading(false);
              Swal.fire({
                title: "Technical Issue, Try after an hour!",
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else {
              setIsloading(false);
            }
          })
          .catch((e) => {
            setIsloading(false);
            if (e?.response?.status == 401) {
              localStorage.removeItem("token");
              localStorage.removeItem("token");
              window.location.reload();
              setTimeout(() => {
                //  history.push("/login")
              }, 500);
            }
            if (e?.response?.status == 400 || e?.response?.status == 429) {
              Swal.fire({
                title: "Please refresh!",
                icon: "warning",
                confirmButtonText: "OK",
              });
            }
            console.log(e);
          });
      } else {
        setIsloading(false);
        Swal.fire({
          title: "Please Amount should be greater 550 and less 100000!",
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
    } else {
      axios
        .post(
          baseUrl + `challange/create`,
          {
            Game_Ammount,
            Game_type: game_type,
          },
          { headers }
        )
        .then((res) => {
          setGame_amt(res?.data?.data?.Game_Ammount);

          if (res.data.status == false) {
            setIsloading(false);
            Swal.fire({
              title: res.data.msg,
              icon: "warning",
              confirmButtonText: "OK",
            });
          } else if (res.data.msg === "you have already enrolled") {
            setIsloading(false);
            Swal.fire({
              title: "You have already enrolled",
              icon: "warning",
              confirmButtonText: "OK",
            });
          } else if (res.data.msg === "You can set maximum 2 battle.") {
            setIsloading(false);
            Swal.fire({
              title: "You can set maximum 2 battle.",
              icon: "warning",
              confirmButtonText: "OK",
            });
          } else if (res.data.msg === "Insufficient balance") {
            setIsloading(false);
            Swal.fire({
              title: "Insufficient balance",
              icon: "warning",
              confirmButtonText: "OK",
            });
          } else if (
            res.data.msg ===
            "Game amount should be Greater then 50 and less then 50000"
          ) {
            setIsloading(false);
            Swal.fire({
              title:
                "Game amount should be Greater then 50 and less then 50000",
              icon: "warning",
              confirmButtonText: "OK",
            });
          } else if (res.data.msg === "Set Battle in denomination of 50") {
            setIsloading(false);
            Swal.fire({
              title: "Set Battle in denomination of 50",
              icon: "warning",
              confirmButtonText: "OK",
            });
          } else if (res.data.msg === "Technical Issue, Try after an hour!") {
            setIsloading(false);
            Swal.fire({
              title: "Technical Issue, Try after an hour!",
              icon: "warning",
              confirmButtonText: "OK",
            });
          } else {
            setIsloading(false);
          }
        })
        .catch((e) => {
          setIsloading(false);
          if (e?.response?.status == 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("token");
            window.location.reload();
            setTimeout(() => {
              //  history.push("/login")
            }, 500);
          }
          if (e?.response?.status == 400 || e?.response?.status == 429) {
            Swal.fire({
              title: "Please refresh!",
              icon: "warning",
              confirmButtonText: "OK",
            });
          }
          console.log(e);
        });
    }
  };

  //open bat

  const [MyopenBattle, setMyOpenBattle] = useState([]);
  const [OtheropenBattle, setOtherMyOpenBattle] = useState([]);
  const [MyrunningBattle, setMyRunningBattle] = useState([]);
  const [OtherrunningBattle, setOtherRunningBattle] = useState([]);

  useEffect(() => {
    socket.emit("getgamelist", userId);
    socket.on("setGamelist", (data) => {
      setGameList(data);
    
    });
  }, []);

  useEffect(() => {
    io.on("gameCreate", (data) => {
      if (
        data?.Accepetd_By?._id === userId ||
        data?.Created_by?._id === userId
      ) {
        if (!MyopenBattle.some((item) => item._id === data._id)) {
          setMyOpenBattle((MyopenBattle) => [...MyopenBattle, data]);
        }
      } else {
        if (!OtheropenBattle.some((item) => item?._id === data?._id)) {
          setOtherMyOpenBattle((OtheropenBattle) => [data, ...OtheropenBattle]);
        }
      }
    });
    io.on("deletebat", (data) => {
      // Log the _id values in the MyopenBattle array
      setMyOpenBattle((MyopenBattle) => {
        const updatedMyOpenBattle = MyopenBattle.filter(
          (item) => item._id !== data
        );
        return updatedMyOpenBattle;
      });
      setOtherMyOpenBattle((OtheropenBattle) => {
        const updatedOtheropenBattle = OtheropenBattle.filter(
          (item) => item._id !== data
        );
        return updatedOtheropenBattle;
      });
    });

    io.on("gameUpdate", (data) => {
      setMyOpenBattle((MyopenBattle) => {
        const updatedMyOpenBattle = MyopenBattle.filter(
          (item) => item?._id !== data?._id
        );
        return updatedMyOpenBattle;
      });
      setOtherMyOpenBattle((OtheropenBattle) => {
        const updatedOtheropenBattle = OtheropenBattle.filter(
          (item) => item?._id !== data?._id
        );
        return updatedOtheropenBattle;
      });
      if (
        data?.Accepetd_By?._id === userId ||
        data?.Created_by?._id === userId
      ) {
        if (!MyopenBattle.some((item) => item._id === data._id)) {
          setMyOpenBattle((MyopenBattle) => [...MyopenBattle, data]);
        }
      } else {
        if (!OtheropenBattle.some((item) => item._id === data._id)) {
          setOtherMyOpenBattle((OtheropenBattle) => [data, ...OtheropenBattle]);
        }
      }
    });

    io.on("startAccepted", (data) => {
      setMyOpenBattle((MyopenBattle) => {
        const updatedMyOpenBattle = MyopenBattle.filter(
          (item) => item?._id !== data?._id
        );
        return updatedMyOpenBattle;
      });
      setOtherMyOpenBattle((OtheropenBattle) => {
        const updatedOtheropenBattle = OtheropenBattle.filter(
          (item) => item?._id !== data?._id
        );
        return updatedOtheropenBattle;
      });

      if (data.Status === "running" && data?.Created_by?._id === userId) {
        if (!MyrunningBattle.some((item) => item._id === data._id)) {
          setMyOpenBattle((MyrunningBattle) => [...MyrunningBattle, data]);
        }
      } else if (
        data.Status === "running" &&
        data?.Accepetd_By?._id === userId
      ) {
        if (!MyopenBattle?.some((item) => item?._id === data?._id)) {
          setMyOpenBattle((MyopenBattle) => [...MyopenBattle, data]);
        }
      } else {
        if (!OtherrunningBattle?.some((item) => item?._id === data?._id)) {
          setOtherRunningBattle((OtherrunningBattle) => [
            data,
            ...OtherrunningBattle,
          ]);
        }
      }
    });

    io.on("acceptor_seen_game", (data) => {
      // debugger
      setMyOpenBattle((MyopenBattle) => {
        const updatedMyOpenBattle = MyopenBattle?.filter(
          (item) => item?._id !== data?._id
        );
        return updatedMyOpenBattle;
      });
      setOtherMyOpenBattle((OtheropenBattle) => {
        const updatedOtheropenBattle = OtheropenBattle?.filter(
          (item) => item?._id !== data?._id
        );
        return updatedOtheropenBattle;
      });

      if (
        data?.Accepetd_By?._id === userId ||
        data?.Created_by?._id === userId
      ) {
        if (!MyrunningBattle.some((item) => item._id === data._id)) {
          setMyOpenBattle((MyrunningBattle) => [...MyrunningBattle, data]);
        }
      }
    });
    io.on("challangeAccept", (data) => {
      setMyOpenBattle((MyopenBattle) => {
        const updatedMyOpenBattle = MyopenBattle?.filter(
          (item) => item._id !== data._id
        );
        return updatedMyOpenBattle;
      });
      setOtherMyOpenBattle((OtheropenBattle) => {
        const updatedOtheropenBattle = OtheropenBattle?.filter(
          (item) => item._id !== data._id
        );
        return updatedOtheropenBattle;
      });

      if (
        data?.Accepetd_By?._id === userId ||
        data?.Created_by?._id === userId
      ) {
        if (!MyrunningBattle.some((item) => item._id === data._id)) {
          setMyOpenBattle((MyrunningBattle) => [...MyrunningBattle, data]);
        }
      }
    });

  }, [io]);


  function winnAmount(gameAmount) {
    let profit = null;
    if (gameAmount >= 50 && gameAmount <= 450)
      profit = (gameAmount * addCaseSetting?.commissionRange1) / 100;
    else if (gameAmount >= 450 && gameAmount <= 50000) {
      profit = (gameAmount * addCaseSetting?.commissionRange3) / 100;
    }
    else if (gameAmount == 5) profit = 1;
    else if (gameAmount == 10) profit = 2;
    else if (gameAmount == 20) profit = 4;
    else if (gameAmount == 30) profit = 6;
    else if (gameAmount == 40) profit = 8;
    return gameAmount - profit;
  }
  useEffect(() => {
    // walletUpdate();
    let owenedCreated = [];
    let remainingGame = [];

    GameList?.openBattle?.forEach(function (ele) {
      if (ele?.Created_by)
        if (
          (ele?.Created_by?._id == userId || ele?.Accepetd_By?._id == userId) &&
          (ele?.Status == "new" || ele?.Status == "requested")
        ) {
          owenedCreated.push(ele);
        } else if (ele?.Status == "new") {
          remainingGame.push(ele);
        } else if (
          ele?.Status == "running" &&
          ele?.Accepetd_By?._id == userId &&
          ele?.Acceptor_seen === false
        ) {
          owenedCreated.push(ele);
        }
    });
    setMyOpenBattle(owenedCreated);
    setOtherMyOpenBattle(remainingGame);
    let owenedRunning = [];
    let remainingRunning = [];
    GameList?.runningBattle?.forEach(function (ele) {
      if (ele.Created_by && ele.Accepetd_By)
        if (ele.Created_by?._id == userId || ele.Accepetd_By?._id == userId) {
          owenedRunning.push(ele);
        } else {
          remainingRunning.push(ele);
        }
    });
    setMyRunningBattle(owenedRunning);

    setOtherRunningBattle(remainingRunning);
    // walletUpdate();
  }, [GameList]);

  useEffect(() => {
    io.on("resultUpdateReq1", (data) => {
      setMyOpenBattle((MyopenBattle) => {
        const updatedMyOpenBattle = MyopenBattle?.filter(
          (item) => item?._id !== data?._id
        );
        return updatedMyOpenBattle;
      });

      if (data.Status === "cancelled" || data.Status === "completed") {
        setMyRunningBattle((MyrunningBattle) => {
          const updatedowenedRunning = MyrunningBattle?.filter(
            (item) => item?._id !== data?._id
          );
          return updatedowenedRunning;
        });
        setOtherRunningBattle((OtherrunningBattle) => {
          const updatedrunningGames = OtherrunningBattle?.filter(
            (item) => item?._id !== data?._id
          );
          return updatedrunningGames;
        });
      } else {
        // Check if the user's ID matches Created_by or Accepted_By
        const isUserOwned =
          data?.Created_by?._id === userId || data?.Accepetd_By?._id === userId;

        setMyRunningBattle((MyrunningBattle) => {
          const updatedowenedRunning = MyrunningBattle?.filter(
            (item) => item?._id !== data?._id
          );
          if (isUserOwned) {
            updatedowenedRunning.push(data);
          }
          return updatedowenedRunning;
        });

        setOtherRunningBattle((OtherrunningBattle) => {
          const updatedrunningGames = OtherrunningBattle?.filter(
            (item) => item?._id !== data?._id
          );
          if (!isUserOwned) {
            updatedrunningGames.push(data);
          }
          return updatedrunningGames;
        });
      }
    });
  }, [io, userId]);



  useEffect(() => {
    let access_token = localStorage.getItem("token");
    access_token = localStorage.getItem("token");
    if (!access_token) {
      window.location.reload();
      setTimeout(() => {}, 500);
    }

    return () => {
    };
  }, []);

  // const RunningGames = async () => {
  //   const access_tokena = localStorage.getItem("token");
  //   const headers = {
  //     Authorization: `Bearer ${access_token}`,
  //   };

  //   axios
  //     .get(baseUrl + `challange/running/all`, { headers })
  //     .then((res) => {
  //       // setRunGames(res.data)
  //       let owenedRunning = [];
  //       let remainingRunning = [];
  //       res?.data?.forEach(function (ele) {
  //         if (ele.Created_by && ele.Accepetd_By)
  //           if (
  //             ele.Created_by?._id == userId ||
  //             ele.Accepetd_By?._id == userId
  //           ) {
  //             owenedRunning.push(ele);
  //           } else {
  //             remainingRunning.push(ele);
  //           }
  //       });
  //       setMyRunningBattle(owenedRunning);
  //       setOtherRunningBattle(remainingRunning);
  //     })
  //     .catch((e) => {
  //       console.log("errror", e);
  //     });
  // };

  //accept Challange

  const AcceptChallang = (id) => {
    if (str == 1) {
      Swal.fire({
        title: "Other request in process",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
    str = 1;
    const access_token = localStorage.getItem("token");

    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .put(
        baseUrl + `challange/accept/${id}`,
        {
          Accepetd_By: headers,
          Acceptor_by_Creator_at: Date.now(),
        },
        {
          headers,
        }
      )
      .then((res) => {
        setGameData({
          accept_by: res.data.Accepetd_By,
          created_by: res.data.Created_by,
        });

        str = 0;
        if (res.data.status === false) {
          Swal.fire({
            title: res.data.msg,
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        if (res.data.msg === "you have already enrolled") {
          Swal.fire({
            title: "You have already enrolled",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        if (res.data.msg === "Insufficient balance") {
          Swal.fire({
            title: "Insufficient balance",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else {
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {}, 500);
        }
        if (e.response.status == 400) {
          Swal.fire({
            title: e.response.data,
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        if (e.response.status == 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  //reject Game
  const RejectGame = (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .put(
        baseUrl + `challange/reject/${id}`,
        {
          Accepetd_By: null,
          Status: "new",
          Acceptor_by_Creator_at: null,
        },
        { headers }
      )
      .then((res) => {
        // io.emit("gameRejected");
      })
      .catch((e) => {
        console.log(e);
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status == 400 || e.response.status == 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  //delete
  const deleteChallenge = (_id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .delete(baseUrl + `challange/delete/${_id}`, {
        headers,
      })
      .then((res) => {
        if (res.data.status == false) {
          Swal.fire({
            title: res.data.msg,
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        // io.emit("deleteGame", _id);
      })
      .catch((e) => {
        if (e?.response?.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status == 400 || e.response.status == 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
      });
  };

  ///challange/running/update/

  const updateChallenge = (_id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .put(
        baseUrl + `challange/running/update/${_id}`,
        {
          Acceptor_seen: true,
        },
        { headers }
      )
      .then((res) => {
        localStorage.setItem("timeOver", "timeStart");
        // io.emit("game_seen");
      })
      .catch((e) => {
        if (e.response.status == 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status == 400 || e.response.status == 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        console.log(e);
      });
  };

  // LUTOLITE CREATE TABLE

  const ChallengeCreateLite = (e) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    axios
      .post(
        baseUrl + `challange/createLite`,
        {
          Game_Ammount,
          Game_type: game_type,
        },
        { headers }
      )
      .then((res) => {
        if (res.data.msg === "you can not create same amount challenge.") {
          Swal.fire({
            title: "you can not create same amount challenge.",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else if (res.data.msg === "you have already enrolled") {
          Swal.fire({
            title: "You have already enrolled",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else if (res.data.status === false) {
          Swal.fire({
            title: res.data.msg,
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else if (res.data.msg === "Technical Issue, Try after an hour!") {
          Swal.fire({
            title: "Technical Issue, Try after an hour!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        } else {
          socket.emit("gameCreated");
        }
      })
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("token");
          window.location.reload();
          setTimeout(() => {
            //  history.push("/login")
          }, 500);
        }
        if (e.response.status === 400 || e.response.status === 429) {
          Swal.fire({
            title: "Please refresh!",
            icon: "warning",
            confirmButtonText: "OK",
          });
        }
        console.log(e);
      });
  };

  // useEffect(()=>{
  //   RunningGames()
  // },[io])
  const getPost = async (Id) => {
    if (
      game_type === "Ludo Classics" ||
      game_type === "Ludo Ulta" ||
      game_type === "Ludo Snake" ||
      game_type === "Ludo 1 Goti" ||
      game_type === "Ludo Lite" ||
      game_type === "LudoPrime" ||
      game_type === "Ludo Host"
    ) {
      if (submitProcess) {
        setProcess(false);
        setChallangeReqRun(true);

        const access_token = localStorage.getItem("token");

        const headers = {
          authorization: `Bearer ${access_token}`,
        };

        axios
          .patch(
            baseUrl + `challange/requested/running/${Id}`,
            {},
            { headers }
          )
          .then((res) => {
            setProcess(true);
            setChallangeReqRun(false);
            if (res.data.status == false) {
              Swal.fire({
                title: res.data.msg,
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else {
              history.push(`/viewgame1/${Id}`, {
                prevPath: window.location?.pathname,
              });
            }
          })
          .catch((e) => {
            setProcess(true);
            setChallangeReqRun(false);
            if (e?.response?.status == 401) {
              Swal.fire({
                title: "Somthing wrong",
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else {
              Swal.fire({
                title: "Please refresh!",
                icon: "warning",
                confirmButtonText: "OK",
              });
            }
          });
      }
    } else if (
      game_type === "LudoSnake" ||
      game_type === "Ludo Popular" ||
      game_type === "Ludo Lite" ||
      game_type === "ludoClassicManual" ||
      game_type === "ludoClassicRich"
    ) {
      if (submitProcess) {
        setProcess(false);
        setChallangeReqRun(true);

        const access_token = localStorage.getItem("token");

        const headers = {
          authorization: `Bearer ${access_token}`,
        };

        axios
          .patch(baseUrl + "challange/requested/running/" + Id, {}, { headers })
          .then((res) => {
            setProcess(true);
            setChallangeReqRun(false);

            if (res.data.status == false) {
              Swal.fire({
                title: res.data.msg,
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else {
              history.push(`/viewgame1/${Id}`, {
                prevPath: window.location?.pathname,
              });
            }
          })
          .catch((e) => {
            setProcess(true);
            setChallangeReqRun(false);
            if (e?.response?.status == 401) {
              Swal.fire({
                title: "Somthing wrong",
                icon: "warning",
                confirmButtonText: "OK",
              });
            } else {
              Swal.fire({
                title: "Please refresh!",
                icon: "warning",
                confirmButtonText: "OK",
              });
            }
          });
      }
    }
  };

  const handleButtonClick = (gameType) => {
    setGame_type(gameType);
  };
  // Socket for GameModeChange list

  const GameModeChange = () => {
    socket.on("setGamelist", (modeData) => {
      setGameList(modeData);
    });
  };

  return (
    <>
      <Header user={users} />
      <div
        className="leftContainer mb_space "
        style={{ minHeight: "100vh", height: "100%" }}
      >
        <div className="pt-3 mt-5   Orher_page_main_section">
          {/* SITE MESSAGE */}
          <div className="alrt_msg_div">
            {game_type === "Ludo Popular" ? (
              <h5 className="">
                {JSON.parse(localStorage.getItem("sitSetting")) === "undefined"
                  ? ""
                  : JSON.parse(localStorage.getItem("sitSetting"))
                      ?.ludoClassicAutobattleMsg}
              </h5>
            ) : game_type === "Ludo Lite" ? (
              <h5 className="">
                {JSON.parse(localStorage.getItem("sitSetting")) === "undefined"
                  ? ""
                  : JSON.parse(localStorage.getItem("sitSetting"))
                      ?.ludoClassicManualMsg}
              </h5>
            ) : (
              <h5 className="">
                {JSON.parse(localStorage.getItem("sitSetting")) === "undefined"
                  ? ""
                  : JSON.parse(localStorage.getItem("sitSetting"))
                      ?.ludoClassicAutoMsg}
              </h5>
            )}
          </div>

          <div>
            {/* Create a Battle */}
            {JSON.parse(localStorage.getItem("sitSetting")) == "undefined" ? (
              ""
            ) : JSON.parse(localStorage.getItem("sitSetting"))?.gameSearch ==
              true ? (
              <>
                <span className="other_page_small_headings">
                  Create a Battle!
                </span>

                <div className="user_reder_code_box mt-2 mx-auto d-flex my-2 w50">
                  {addCaseSetting.gameSearch && game_type2 != "new" ? (
                    <input
                      className="commaon_input_box"
                      type="tel"
                      placeholder="Enter amount"
                      value={Game_Ammount}
                      onChange={(e) => setGame_Ammount(e.target.value)}
                    />
                  ) : null}

                  <div className="set check_btn w-25 ">
                    {!isLoading ? (
                      <>
                        {addCaseSetting.gameSearch && game_type2 !== "new" ? (
                          <>
                            {game_type === "Ludo Lite" ? (
                              <button
                                className="samll_btn btn-1 "
                                onClick={(e) => {
                                  e.preventDefault();
                                  setGame_Ammount("");
                                  ChallengeCreateLite();
                                }}
                              >
                                Set
                              </button>
                            ) : (
                              <button
                                className="samll_btn btn-1"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setGame_Ammount("");
                                  ChallengeCreate();
                                }}
                              >
                                Set
                              </button>
                            )}
                          </>
                        ) : null}
                      </>
                    ) : (
                      <button
                        className={`bg-green ${css.playButton} cxy m-1 position-static `}
                        disabled
                      >
                        <Spinner
                          as="span"
                          animation="grow"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        Loading...
                      </button>
                    )}
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            <div className="py-3">
              <div className="mb-3">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/Homepage/battleIcon.png"
                  }
                  alt=""
                  width="20px"
                />
                <span
                  className={`ml-2 ${css.gamesSectionTitle} games_Section_Title`}
                >
                  Open Battles
                </span>

                <span
                  className={`${css.gamesSectionHeadline} text-uppercase position-absolute mt-2 font-weight-bold text-success`}
                  style={{ right: "1.5rem" }}
                >
                  Rules
                  <NavLink to="/Rules">
                    <img
                      className="ml-2"
                      src={process.env.PUBLIC_URL + "/images/Homepage/info.png"}
                      alt=""
                      width="16px"
                    />
                  </NavLink>
                </span>
              </div>

             <>
             
             {/* {userData.Wallet_balance != '0' || OtheropenBattle.Game_Ammount > userData.Wallet_balance ?  */}
             {/* {userData.Wallet_balance != '0' ? */}
             {MyopenBattle &&
                MyopenBattle.filter(
                  (allgame) => allgame.Game_type === game_type
                ).map((allgame, index) => (
                  <div
                    className={`${css.betCard} mt-2  battle_card_main card_bottom_border`}
                  >
                    <span
                      className={`${css.betCardTitle}  d-flex align-items-center  ${css.betTitleDiv} headings`}
                    >
                      Challange From
                      <span className="ml-1" style={{ color: "brown" }}>
                        {allgame?.Created_by?.Name}
                      </span>
                      {userId == allgame?.Created_by?._id &&
                        allgame?.Status == "new" && (
                          <button
                            className={`  ml-auto delete_buttons`}
                            onClick={() => deleteChallenge(allgame?._id)}
                          >
                            {/* DELETE */}
                            <MdOutlineDelete className="card_icons" />
                          </button>
                        )}
                      {userId == allgame?.Created_by?._id &&
                        allgame?.Status == "requested" && (
                          <div className="d-flex ml-auto align-items-center">
                            <Link style={{ bottom: "0" }}>
                              <button
                                className={`bg-success position-relative mx-1 btn-sm text-white btn-inverse-success`}
                                disabled={disable}
                                style={{
                                  display: disable ? "none" : "block",
                                }}
                                onClick={(e) => {
                                  disablebtn(allgame?._id);
                                  req_chall_fun(allgame?._id);
                                  // getPost(allgame?._id);
                                  // sendMessage()
                                  // history.push(`/viewgame1/${allgame?._id}`, {  prevPath: window.location.pathname })
                                }}
                              >
                                Start
                              </button>
                              <button
                                className={
                                  disable
                                    ? "btn btn-primary d-block"
                                    : "btn btn-primary d-none"
                                }
                              >
                                Wait
                              </button>
                            </Link>
                            <button
                              className={
                                disable
                                  ? "d-none text-white bg-danger position-relative mx-1 btn-sm btn-outline-youtube"
                                  : "text-white bg-danger position-relative mx-1 btn-sm btn-outline-youtube d-block"
                              }
                              onClick={() => RejectGame(allgame?._id)}
                              style={{ bottom: "0" }}
                            >
                              Reject
                            </button>
                          </div>
                        )}
                    </span>
                    <div
                      className={`  ${css.betBodyDiv} battle_card_inner_bottom`}
                    >
                      {/* Entry Fee */}
                      <div className="">
                        <span className={css.betCardSubTitle}>Entry Fee</span>
                        <div>
                        <img width={20} src="/images/rupee.png"/>
                          <span className={css.betCardAmount}>
                            {allgame?.Game_Ammount}
                          </span>
                        </div>
                      </div>
                      {userId !== allgame?.Created_by?._id &&
                        allgame?.Status == "new" && (
                          <button
                            className={`   samll_btn  battle_plat_btn play_btn_color`}
                            disabled={disable_play}
                            onClick={() => {
                              disable_play_btn();
                              AcceptChallang(allgame?._id);
                            }}
                          >
                            Play
                          </button>
                        )}
                      {userId == allgame?.Created_by?._id &&
                        allgame?.Status == "new" && (
                          <div className="pl-2 text-center">
                            <img
                              src={findGif}
                              style={{ width: "25px", height: "25px" }}
                            />
                          </div>
                        )}
                      {userId !== allgame?.Created_by?._id &&
                        allgame?.Status == "requested" && (
                          <div className="d-flex  align-items-center">
                            <div
                              className={`  position-relative mx-1 text-white btn-sm request_btn`}
                            >
                              requested
                            </div>
                            <button
                              className={`  position-relative mx-1 btn-sm reject_btn`}
                              onClick={() => RejectGame(allgame?._id)}
                            >
                              cancel
                            </button>
                          </div>
                        )}
                      {userId !== allgame?.Created_by?._id &&
                        allgame?.Status == "running" && (
                          <div className="">
                            <audio src={playSound} autoPlay></audio>

                            <Link>
                              <button
                                disabled={disable}
                                className={`samll_btn start_btn_color'`}
                                style={{ background: "#783cd6" }}
                                onClick={(e) => {
                                  disablebtn();
                                  updateChallenge(allgame?._id);
                                  history.push(`/viewgame1/${allgame?._id}`, {
                                    prevPath: window.location?.pathname,
                                  });
                                }}
                              >
                                Start
                              </button>
                            </Link>
                          </div>
                        )}
                      {userId == allgame?.Created_by?._id &&
                        allgame.Status == "requested" && (
                          <div className="">
                            <audio src={acceptSound} autoPlay></audio>

                            <div className="text-center col">
                              <div className="pl-2">
                                {allgame?.Accepetd_By?.avatar ? (
                                  <img
                                    src={
                                      baseUrl +
                                      `${allgame?.Accepetd_By?.avatar}`
                                    }
                                    alt=""
                                    width="40px"
                                    height="40px"
                                    style={{
                                      borderTopLeftRadius: "50%",
                                      borderTopRightRadius: "50%",
                                      borderBottomRightRadius: "50%",
                                      borderBottomLeftRadius: "50%",
                                      marginTop: "5px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src="/images/avatars/Avatar1.png"
                                    alt=""
                                    width="25px"
                                    height="25px"
                                    style={{
                                      borderTopLeftRadius: "50%",
                                      borderTopRightRadius: "50%",
                                      borderBottomRightRadius: "50%",
                                      borderBottomLeftRadius: "50%",
                                      marginTop: "5px",
                                    }}
                                  />
                                )}
                              </div>
                              <div style={{ lineHeight: 1 }}>
                                <span className="bet_player_name">
                                  {allgame?.Accepetd_By.Name}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}

                      {/* prize */}
                      <div>
                        <span className={css.betCardSubTitle}>Prize</span>
                        <div>
                          <span className={css.betCardAmount}>
                          <img width={20} src="/images/rupee.png"/>
                            {allgame?.Game_Ammount +
                              winnAmount(allgame?.Game_Ammount)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
             
             {/* {userData.Wallet_balance != '0'  ?  */}
             {OtheropenBattle &&
                OtheropenBattle?.filter(
                  (allgame) => allgame.Game_type === game_type
                ).map((allgame, index) => (
                  <>
                  {/* {
                     userData.Wallet_balance  >=  allgame.Game_Ammount ? */}

                  <div
                    className={`${css.betCard} mt-2  battle_card_main card_bottom_border`}
                  >
                    <span
                      className={`${css.betCardTitle}  d-flex align-items-center  ${css.betTitleDiv} headings`}
                    >
                      Challange From
                      <span className="ml-1" style={{ color: "brown" }}>
                        {allgame?.Created_by?.Name}
                      </span>
                      {userId == allgame?.Created_by?._id &&
                        allgame?.Status == "new" && (
                          <button
                            className={`  ml-auto delete_buttons`}
                            onClick={() => deleteChallenge(allgame?._id)}
                          >
                            {/* DELETE */}
                            <MdOutlineDelete className="card_icons" />
                          </button>
                        )}
                      {userId == allgame?.Created_by?._id &&
                        allgame?.Status == "requested" && (
                          <div className="d-flex ml-auto align-items-center">
                            <Link style={{ bottom: "0" }}>
                              <button
                                className={`bg-success position-relative mx-1 btn-sm text-white btn-inverse-success`}
                                disabled={disable}
                                style={{
                                  display: disable ? "none" : "block",
                                }}
                                onClick={(e) => {
                                  disablebtn(allgame?._id);
                                  req_chall_fun(allgame?._id);
                                }}
                              >
                                Start
                              </button>
                              <button
                                className={
                                  disable
                                    ? "btn btn-primary d-block"
                                    : "btn btn-primary d-none"
                                }
                              >
                                Wait
                              </button>
                            </Link>
                            <button
                              className={
                                disable
                                  ? "d-none text-white bg-danger position-relative mx-1 btn-sm btn-outline-youtube"
                                  : "text-white bg-danger position-relative mx-1 btn-sm btn-outline-youtube d-block"
                              }
                              onClick={() => RejectGame(allgame?._id)}
                              style={{ bottom: "0" }}
                            >
                              Reject
                            </button>
                          </div>
                        )}
                    </span>
                    <div
                      className={`  ${css.betBodyDiv} battle_card_inner_bottom`}
                    >
                      {/* Entry Fee */}
                      <div className="">
                        <span className={css.betCardSubTitle}>Entry Fee</span>
                        <div>
                        <img width={20} src="/images/rupee.png"/>
                          <span className={css.betCardAmount}>
                            {allgame?.Game_Ammount}
                          </span>
                        </div>
                      </div>
                      

                      {/* prize */}
                      <div>
                        <span className={css.betCardSubTitle}>Prize</span>
                        <div>
                          <span className={css.betCardAmount}>
                          <img width={20} src="/images/rupee.png"/>
                            {allgame?.Game_Ammount +
                              winnAmount(allgame?.Game_Ammount)}
                          </span>
                        </div>
                      </div>
                     <div className="mt-15">
                     {userId !== allgame?.Created_by?._id &&
                        allgame?.Status == "new" && (
                          <button
                            className={`   samll_btn  battle_plat_btn play_btn_color`}
                            disabled={disable_play}
                            onClick={() => {
                              disable_play_btn();
                              AcceptChallang(allgame?._id);
                            }}
                          >
                            Play
                          </button>
                        )}
                      {userId == allgame?.Created_by?._id &&
                        allgame?.Status == "new" && (
                          <div className="pl-2 text-center">
                            <img
                              src={findGif}
                              style={{ width: "25px", height: "25px" }}
                            />
                          </div>
                        )}
                      {userId !== allgame?.Created_by?._id &&
                        allgame?.Status == "requested" && (
                          <div className="d-flex  align-items-center">
                            <div
                              className={`  position-relative mx-1 text-white btn-sm request_btn`}
                            >
                              requested
                            </div>
                            <button
                              className={`  position-relative mx-1 btn-sm reject_btn`}
                              onClick={() => RejectGame(allgame?._id)}
                            >
                              cancel
                            </button>
                          </div>
                        )}

                      {userId !== allgame?.Created_by?._id &&
                        allgame?.Status == "running" && (
                          <div className="">
                            <audio src={playSound} autoPlay></audio>
                            <Link>
                              <button
                                disabled={disable}
                                className={`samll_btn start_btn_color'`}
                                // className={` ${css.playButton} ${css.cxy}  samll_btn '`}
                                style={{ background: "#783cd6" }}
                                onClick={(e) => {
                                  disablebtn();
                                  updateChallenge(allgame?._id);
                                  history.push(`/viewgame1/${allgame?._id}`, {
                                    prevPath: window.location?.pathname,
                                  });
                                }}
                              >
                                Start
                              </button>
                            </Link>
                          </div>
                        )}
                      {userId == allgame?.Created_by?._id &&
                        allgame.Status == "requested" && (
                          <div className="">
                            <audio src={acceptSound} autoPlay></audio>

                            <div className="text-center col">
                              <div className="pl-2">
                                {allgame?.Accepetd_By?.avatar ? (
                                  <img
                                    src={
                                      baseUrl +
                                      `${allgame?.Accepetd_By?.avatar}`
                                    }
                                    alt=""
                                    width="40px"
                                    height="40px"
                                    style={{
                                      borderTopLeftRadius: "50%",
                                      borderTopRightRadius: "50%",
                                      borderBottomRightRadius: "50%",
                                      borderBottomLeftRadius: "50%",
                                      marginTop: "5px",
                                    }}
                                  />
                                ) : (
                                  <img
                                    src="/images/avatars/Avatar1.png"
                                    alt=""
                                    width="40px"
                                    height="40px"
                                    style={{
                                      borderTopLeftRadius: "50%",
                                      borderTopRightRadius: "50%",
                                      borderBottomRightRadius: "50%",
                                      borderBottomLeftRadius: "50%",
                                      marginTop: "5px",
                                    }}
                                  />
                                )}
                              </div>
                              <div style={{ lineHeight: 1 }}>
                                <span className="bet_player_name">
                                  {allgame?.Accepetd_By.Name}
                                </span>
                              </div>
                            </div>
                          </div>
                        )}
                     </div>
                    </div>
                  </div>
                  {/* : ""
                  } */}
                   </>

                ))}
                </>
            </div>
            <div className="battle_divider"></div>
            <div className="mb-5 running-battel">
              <div className="">
                <img
                  src={
                    process.env.PUBLIC_URL + "/images/Homepage/battleIcon-1.png"
                  }
                  alt=""
                  width="20px"
                />
                <span
                  className={`ml-2 mb-2 ${css.gamesSectionTitle} games_Section_Title `}
                  style={{ color: "black", margin: "30px" }}
                >
                  Running Battles
                </span>

                {/* <button
                  onClick={RunningGames}
                  style={{
                    backgroundColor: "transparent",
                    color: "#000",
                    borderRadius: "4px",
                    padding: "4px 2px",
                    fontSize: "12px",
                    border: "none",
                  }}
                >
                  <FiRefreshCcw style={{ margin: "5px" }} />
                </button> */}
              </div>

              {MyrunningBattle &&
                MyrunningBattle.map((runnig) => {
                  if (
                    ((userId == runnig.Accepetd_By._id
                      ? (runnig.Status === "running" &&
                          userId == runnig.Accepetd_By._id &&
                          runnig.Acceptor_seen == true) ||
                        runnig.Status === "pending"
                      : (runnig.Status === "running" &&
                          userId == runnig.Created_by._id) ||
                        runnig.Status === "pending") ||
                      runnig.Status == "conflict") &&
                    (runnig.Game_type == game_type ||
                      runnig.Game_type == "ludoClassicManual" ||
                      runnig.Game_type == "Ludo Popular" ||
                      runnig.Game_type == "Ludo Lite")
                  )
                    return (
                      <RunningCard
                        key={runnig._id}
                        runnig={runnig}
                        user={users}
                        winnAmount={winnAmount}
                      />
                    );
                })}

              {OtherrunningBattle &&
                OtherrunningBattle.map((runnig) => {
                  if (
                    (
                    userId === runnig.Accepetd_By._id ||
                    userId === runnig.Created_by._id
                      ? userId == runnig.Accepetd_By._id
                        ? (runnig.Status === "running" &&
                            userId == runnig.Accepetd_By._id &&
                            runnig.Acceptor_seen == true) ||
                          (runnig.Status === "pending" &&
                            runnig.Acceptor_status == null)
                        : (runnig.Status === "running" &&
                            user == runnig.Created_by._id) ||
                          (runnig.Status === "pending" &&
                            runnig.Creator_Status == null)
                      : runnig.Status === "running" ||
                        runnig.Status === "pending") &&
                    (runnig.Game_type == game_type ||
                      game_type == "ludoClassicManual" ||
                      game_type == "Ludo Popular" )
                  )
                    return (
                      <>
                        <RunningCard
                          key={runnig?._id}
                          runnig={runnig}
                          user={users}
                          winnAmount={winnAmount}
                        />
                      </>
                    );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </>
  );
}
