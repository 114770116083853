import React, { useEffect, useState } from "react";
import css from "../css/Mywallet.module.css";
import Rightcontainer from "../Components/Rightcontainer";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import socket from "../Components/socket";
import { AiOutlineHistory, AiOutlinePlus } from "react-icons/ai";
import { BsWallet2 } from "react-icons/bs";
import io from "../Components/socket";

const Mywallet = ({ user }) => {
  // const [user, setUser] = useState();

  // BASE URL

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const access_token = localStorage.getItem("token");
  //   const walletUpdate =()=>{
  //  io.emit("getprofile", access_token);
  //   io.on("setProfile", (data) => {
  //     setUser(data);
  //   });
  //   }

  //   useEffect(()=>{
  //     walletUpdate()
  //   },[]);

  return (
    <>
      <div className="leftContainer mb_space">
        <div className="pt-3 mt-5  Orher_page_main_section">
          <div className="d-flex align-items-center justify-content-between">
            <div  >
              <a className="btn back_btn" href="/">
                <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
              </a>
            </div>
            <div  >
              <a className="btn back_btn" href="/transaction-history">
                 Wallet History
              </a>
            </div>
          </div>
          <div className="back_btn my-3">
           <div className="wallet_box mt-0">
            <a className="boxes w-100 d-flex align-items-center justify-content-between" href="/wallet">
              <div className="d-flex align-items-center justify-content-between">
                <img src="/images/avatars/Avatar6.png" alt="wallet" width="30px" />
                <div className="ml-2 text-white font-weight-bold">Verified</div>
                <i className="fa fa-check-circle-o text-success ml-1" aria-hidden="true"></i>
              </div>
              <div className="btn bg-dark back_btn rounded">Verification Completed</div>
            </a>
          </div>
        </div>
        <div className="card">
          <div className="bg-1 text-white text-center rounded py-1" style={{ fontSize: '0.9em', fontWeight: 600 }}>
            Deposit Chips
          </div>
          <div className="info p-2 bg-secondary text-center">
            यह चिप्स Win अवं Buy की गई चिप्स है इनसे सिर्फ गेम खेले जा सकते है, बैंक या UPI से निकाला नहीं जा सकता है
          </div>
          <div className="d-flex justify-content-center">
            <div className="shadow rounded py-2 px-5 mt-3 text-center">
              <picture className="ml-1">
                <img width="30px" src="images/rupee.png" alt="chips" />
              </picture>
              <p className="mb-1" style={{ fontSize: '1em', fontWeight: 900 }}>0</p>
              <p className="mb-0">Chips</p>
            </div>
          </div>
          <a className="px-2 my-3" href="/addcase">
            <button className="bg-1 text-white w-100 rounded font-weight-bold py-1 border-0">
              Add
            </button>
          </a>
        </div>
        <div className="card mt-4">
          <div className="bg-1 text-white text-center rounded py-1" style={{ fontSize: '0.9em', fontWeight: 600 }}>
            Winning Chips
          </div>
          <div className="info p-2 bg-secondary text-center">
            यह चिप्स गेम से जीती हुई एवं रेफरल से कमाई हुई है, इन्हे बैंक या UPI में निकाला जा सकता है, इन चिप्स से गेम भी खेला जा सकता है
          </div>
          <div className="d-flex justify-content-center">
            <div className="shadow rounded py-2 px-5 mt-3 text-center">
              <picture className="ml-1">
                <img width="30px" src="images/rupee.png" alt="chips" />
              </picture>
              <p className="mb-1" style={{ fontSize: '1em', fontWeight: 900 }}>0</p>
              <p className="mb-0">Chips</p>
            </div>
          </div>
          <a className="px-2 my-3" href="/Withdrawopt">
            <button className="bg-1 text-white w-100 rounded font-weight-bold py-1 border-0">
              Withdraw
            </button>
          </a>
        </div>
        

         
          {/* <div className="mt-2">
          <div className={css.wallet_card}>
            <div className='d-flex align-items-center'>
              <picture className='mr-1'>
                <img
                  height='26px'
                  width='26px'
                  src={
                    process.env.PUBLIC_URL +
                    'images/global-rupeeIcon.png'
                  }
                  alt=''
                />
              </picture>
              <span
                className='text-white'
                style={{ fontSize: '1.3em', fontWeight: '900' }}
              >
                ₹{user && user.Wallet_balance}
              </span>
            </div>
            <div
              className='text-white text-uppercase'
              style={{ fontSize: '0.9em', fontWeight: '800' }}
            >
              Deposit Cash
            </div>
            <div className={`${css.my_text} mt-5`}>
              Can be used to play Tournaments &amp; Battles.
              <br />
              Cannot be withdrawn to Paytm or Bank.
            </div>
            <Link to='/addcase'>
              <button
                className={`${css.walletCard_btn} d-flex justify-content-center align-items-center text-uppercase`}
              >
                Add Cash
              </button>
            </Link>
          </div>
          <div className={css.wallet_card2}>
            <div className='d-flex align-items-center'>
              <picture className='mr-1'>
                <img
                  height='26px'
                  width='26px'
                  src={
                    process.env.PUBLIC_URL +
                    'images/global-rupeeIcon.png'
                  }
                  alt=''
                />
              </picture>
              <span
                className='text-white'
                style={{ fontSize: '1.3em', fontWeight: '900' }}
              >
                ₹{user && user.withdrawAmount}
              </span>
            </div>
            <div
              className='text-white text-uppercase'
              style={{ fontSize: '0.9em', fontWeight: '800' }}
            >
              Winning Cash
            </div>
            <div className={`${css.my_text2} mt-5`}>
              Can be withdrawn to Paytm or Bank. Can be used to play Tournaments
              &amp; Battles.
            </div>
            <Link to='/Withdrawopt'>
              <button
                className={`${css.walletCard_btn} d-flex justify-content-center align-items-center text-uppercase`}
              >
                Withdraw
              </button>
            </Link>
          </div>
          </div> */}
        </div>
        <div className="rightContainer">
          <div>
            <Rightcontainer />
          </div>
        </div>
      </div>
    </>
  );
};
export default Mywallet;
