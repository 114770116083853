import React, { useState, useEffect } from 'react';



const ColorePredication = () => {
     // State for the quantity value
  const [quantity, setQuantity] = useState(1);

  // Countdown Timer Component
const CountdownTimer = ({ targetDate }) => {

    
  const calculateTimeLeft = () => {
    const difference = targetDate - new Date();
    let timeLeft = {};

    if (difference > 0) {
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      timeLeft = {
        minutes: minutes < 10 ? `0${minutes}` : minutes,
        seconds: seconds < 10 ? `0${seconds}` : seconds,
      };
    } else {
      timeLeft = { minutes: '00', seconds: '00' };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, [targetDate]);

  return (
    <h2>{timeLeft.minutes}:{timeLeft.seconds}</h2>
  );
};

  // Handle increment
  const increment = () => {
    setQuantity(prevQuantity => prevQuantity + 1);
  };

  // Handle decrement
  const decrement = () => {
    setQuantity(prevQuantity => (prevQuantity > 1 ? prevQuantity - 1 : 1));
  };

  const [selectedValue, setSelectedValue] = useState(10);

  const handleClick = (value) => {
    setSelectedValue(value);
  };

  // Handle input change
  const handleChange = (e) => {
    const value = Math.max(1, Number(e.target.value)); // Ensure value is at least 1
    setQuantity(value);
  };   

  const targetDate = new Date(Date.now() + 18 * 1000); // Countdown for 18 seconds for demo

  return (
    <>
      <div className="leftContainer mb_space">
        <div className="pt-3 mt-5 Orher_page_main_section">
          <div className="gameCard card-tabs pt-0">
            <ul className="nav nav-tabs" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" data-bs-toggle="tab" href="#monthly" role="tab">Parity</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-bs-toggle="tab" href="#Weekly" role="tab">Sapre</a>
              </li>
            </ul>
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <p className="mb-0"> <img src={process.env.PUBLIC_URL + '/images/icons/cup.png'} style={{width:"8%", marginRight:"5px"}}/>Period</p>
                <h2 style={{fontFamily:'Poppins'}}>20240621219</h2>
              </div>
              <div>
                <p className="mb-0">Count Down</p>
                <CountdownTimer targetDate={targetDate} />
              </div>
            </div>
            <div className="join-btn-group">
              <button className="btn btn-success text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">Join Green</button>
              <button className="btn text-white" style={{background:'violet'}}>Join Violet</button>
              <button className="btn btn-danger text-white">Join Red</button>
            </div>
            <div className="row mt-3">
              {/* Number buttons */}
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => (
                <div className="col-lg-3 col-sm-3 col-4" key={num}>
                  <button className={`btn-box ${num % 2 === 0 ? 'bg-gradied' : 'btn-success'} text-white ` } data-bs-toggle="modal" data-bs-target="#exampleModal">{num}</button>
                </div>
              ))}
            </div>
            <div className="tab-content" id="myTabContent">
              <div className="tab-pane active show fade" id="monthly" role="tabpanel">
                <div className="win-table mt-5">
                  <h6 className="text-center" style={{fontWeight:"600",fontSize:"18px"}}>
                    <img src={process.env.PUBLIC_URL + '/images/icons/cup.png'} style={{width:"5%", marginRight:"5px"}}/>
                    Parity Record</h6>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Period</th>
                        <th scope="col">Price</th>
                        <th scope="col">Number</th>
                        <th scope="col">Result</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array(4).fill().map((_, idx) => (
                        <tr key={idx}>
                          <th scope="row">20</th>
                          <td>3169</td>
                          <td className="text-success">9</td>
                          <td><div className="points bg-success"></div></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="tab-pane" id="Weekly" role="tabpanel">
                <div className="win-table mt-5">
                  <h6 className="text-center" style={{fontWeight:"600",fontSize:"18px"}}>Sapre Record</h6>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Period</th>
                        <th scope="col">Price</th>
                        <th scope="col">Number</th>
                        <th scope="col">Result</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array(4).fill().map((_, idx) => (
                        <tr key={idx}>
                          <th scope="row">20</th>
                          <td>3169</td>
                          <td className="text-success">9</td>
                          <td><div className="points bg-success"></div></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content p-0">
            <div className="modal-header bg-success">
              <h4 className="modal-title text-white" id="exampleModalLabel"><b>Join Green</b></h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-12">
                  <p className="d-block">Contract Money</p>
                  <div className="btn-group" role="group" aria-label="Basic example">
      <button
        type="button"
        className={`btn ${selectedValue === 10 ? 'btn-primary' : 'btn-light'}`}
        onClick={() => handleClick(10)}
      >
        10
      </button>
      <button
        type="button"
        className={`btn ${selectedValue === 100 ? 'btn-primary' : 'btn-light'}`}
        onClick={() => handleClick(100)}
      >
        100
      </button>
      <button
        type="button"
        className={`btn ${selectedValue === 1000 ? 'btn-primary' : 'btn-light'}`}
        onClick={() => handleClick(1000)}
      >
        1000
      </button>
    </div>
                  <div className="def-number-input number-input safari_only my-3">
                    <button
                        type="button"
                        onClick={decrement}
                        className="minus"
                    >
                        -
                    </button>
                    <input
                        className="quantity"
                        min="1"
                        name="amount"
                        id="amount"
                        type="number"
                        value={quantity}
                        onChange={handleChange}
                        onKeyUp={handleChange} // Ensure value updates on key press
                    />
                    <button
                        type="button"
                        onClick={increment}
                        className="plus"
                    >
                        +
                    </button>
                    </div>
                  <p className="d-block">Total Contract Money is 70</p>
                  
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn text-danger  " data-bs-dismiss="modal">Cancel</button>
              <button type="button" className="btn text-primary">Confirm</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ColorePredication;
