import React from 'react'
import css from '../Modulecss/Home.module.css'
import { Link } from 'react-router-dom'
import Header from '../Components/Header'

export default function RunningCard ({ runnig, winnAmount, game_type  }) {
  const user = localStorage.getItem("userId");
  // console.log(user,"running")
  return (
    <>
   
      <div
        className={`${css.betCard} mt-1 battle_card_main card_bottom_border`}
      >
        <div className='d-flex'>
          <span
            className={`${css.betCardTitle} pl-3 d-flex align-items-center headings`}
          >
            Playing For
            <img className='ml-1' width={20} src="/images/rupee.png"/>
            {runnig.Game_Ammount}
          </span>
          {(user == runnig.Accepetd_By._id ||
            user == runnig.Created_by._id) && (
            <Link
              className={`${css.bgSecondary} ${css.playButton} ${
                css.cxy
              } position-relative mt-2 mx-1 ${
                runnig.Status == 'conflict' ? 'bg-danger' : 'bg-success'
              }`}
              style={{
                right: '0px',
                top: '-6px',
                padding: '10px 17px'
              }}
              to={{
                pathname: `/viewgame1/${runnig._id}`,
                state: { prevPath: window.location.pathname }
              }}
            >
              view
            </Link>
          )}
          <div
            className={`${css.betCardTitle} d-flex align-items-center headings`}
          >
            <span className='ml-auto mr-3'>
              Prize
              <img className='ml-1' width={20} src="/images/rupee.png"/>
              {runnig.Game_Ammount + winnAmount(runnig.Game_Ammount)}
            </span>
          </div>
        </div>
        <div className='py-1 row battle_card_inner_bottom'>
          <div className='pr-3 text-center col-5'>
             <img width={30} src="/images/avatars/Avatar6.png"/>
            <div style={{ lineHeight: 1 }}>
            
              <span className={css.betCard_playerName}>
                {runnig.Created_by.Name}
              </span>
            </div>
          </div>
          <div className='pr-3 text-center col-2 cxy'>
            <div>
              <img
                src={process.env.PUBLIC_URL + '/images/Homepage/versus.png'}
                alt=''
                width='30px'
              />
            </div>
          </div>
          <div className='text-center col-5'>
          <img width={30} src="/images/avatars/Avatar6.png"/>
            <div style={{ lineHeight: 1 }}>
              <span className={css.betCard_playerName}>
                {runnig.Accepetd_By.Name}
              </span>
            </div>
          </div>
        </div>
      </div>
     
    </>
  )
}
