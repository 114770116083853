import React from 'react'
import { useEffect, useState } from 'react'
import css from '../css/notification.module.css'
import Rightcontainer from '../Components/Rightcontainer'
import Button from 'react-bootstrap/Button'
import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Table from 'react-bootstrap/Table'
import { Link } from 'react-router-dom'

const Notification = () => {

  // BASE URL
 
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {

    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
}
// +++++++++++++++++++++++++++++ 
  const [notifications, setNotifications] = useState([])

  const access_token = localStorage.getItem('token')

  useEffect(() => {
    notification_list()
  }, [])

  const notification_list = () => {
    var myHeaders = new Headers()
    myHeaders.append('Authorization', `Bearer ${access_token}`)
    myHeaders.append('Content-Type', 'application/json')

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }

    fetch(
      baseUrl+'list-notification/sendto/all?page=1&limit=10',
      requestOptions
    )
      .then(response => response.json())
      .then(result => {
        setNotifications(result.results) // Set the fetched notifications in the state
      })
      .catch(error => console.log('error', error))
  }

  // formate date
  function formatDate (dateString) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    }
    return new Date(dateString).toLocaleString('en-US', options)
  }

  return (
    <>
      <div>
        <div className='leftContainer'>
          <div className='pt-5 mt-4'>
            <div className=''>
              <div className=' Orher_page_main_section'>
                <h2 className='profile_headings mt-4'>Notifications</h2>

                {notifications && notifications.length > 0 ? (
                  notifications.map(items => (
                    <div className='other_pages_card_main' key={items.id}>
                      <div>
                        <h3>{items?.title}</h3>
                        <p>{items?.title}</p>
                      </div>
                      <div>
                        <p>{formatDate(items.createdAt)}</p>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className='leftContainer'>
                    <div
                      className='cxy flex-column px-4 text-center'
                     
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + '/images/nonotification.png'
                        }
                        width='220px'
                        className='snip-img'
                        alt='no notification'
                      />
                      <div
                        className={`${css.games_section_title} mt-4`}
                        style={{ fontSize: '1.2em' }}
                      >
                        No notification yet!
                      </div>
                      <div
                        className={`${css.games_section_headline} mt-2`}
                        style={{ fontSize: '0.85em' }}
                      >
                        Seems like you haven’t done any activity yet
                      </div>
                    </div>
                  </div>
                )}
               

   
              
              </div>
            </div>
          </div>
          <div className='rightContainer'>
            <Rightcontainer />
          </div>
        </div>
      </div>
      <div className='rightContainer'>
        <Rightcontainer />
      </div>
      
    </>
  )
}

export default Notification
